import { defineStore } from 'pinia'

import { toast } from '@/js/lib/toast.js'

import { useSessionStore } from './session'
import { useCartStore } from './cart'

export const useRewardsStore = defineStore('rewards', {
	state: () => ({
		rewards: [],
		paletteRewards: [],
		rewardCredit: 0,
		rewardDiscounts: 0,
		rewardCreditRemaining: 0,
		discount_items_count: 0,
		rewardDiscountsTotal: 0,
		rewardDiscountsUsed: 0,
		fiftyPercentItemsTotal: 0,
		fiftyPercentItemsUsed: 0,
		reward_exclude_50_percent: [],
		coupon: null,
	}),

	getters: {
		hasRewardCredit: (state) => state.rewardCredit > 0,
		hasRewardDiscounts: (state) => state.fiftyPercentItemsTotal - state.fiftyPercentItemsUsed > 0,
		isExcluded50PercentReward: (state) => (sku) => {
			return (
				state.rewards.find(
					(reward) => reward.reward_id === 'hostess_reward_fifty' && reward.non_qualifying_skus.includes(sku)
				) !== undefined
			)
		},
		remainingDiscountItemCount: (state) => {
			const remaining = state.rewardDiscounts - state.discount_items_count
			return Math.max(remaining, 0)
		},
		rewardCreditUsed: (state) => {
			for (const reward of state.rewards) {
				if (reward.reward_id === 'hostess_reward_credit') {
					const { total_store_credit, qualifying_items_subtotal } = reward
					if (qualifying_items_subtotal > total_store_credit) {
						return -total_store_credit
					}
					return -qualifying_items_subtotal
				}
			}
		},
		paletteCreditRewardLevel: (state) => {
			const levels = state?.paletteRewards?.levels
			if (levels) {
				const levelsTotal = levels.length
				for (let i = levelsTotal - 1; i >= 0; i--) {
					if (levels[i]?.qualifies) {
						return levels[i]
					}
				}
			}
			return null
		},
		orderLevelDiscount: (state) => {
			const APPLICABLE_IDS = ['loyalty']

			const discountRewards = state.rewards.filter(
				(reward) => APPLICABLE_IDS.includes(reward.reward_id) && reward.discount_type === 'dollar'
			)

			// TODO: scary and needs to be careful if we do coupons for this other than dollar

			if (discountRewards.length === 0) return null

			const discounts = []
			let hasReward = false
			for (const discountReward of discountRewards) {
				let achieved = null
				for (const level of discountReward.levels) {
					if (level.qualifies) {
						achieved = level
					}
				}
				if (achieved) {
					hasReward = true
					discounts.push({
						...achieved,
						original: discountReward.qualifying_items_subtotal,
						title: discountReward.title,
						discountType: discountReward.discount_type,
					})
				}
			}

			if (hasReward) {
				// currently focusing on one 'id' for loyalty
				// if we add more before refactoring this mess
				// need to accumulate these
				return {
					discount: discounts[0].credit * -1,
					message: 'Coupon Applied',
				}
			}
			return null
		},
	},

	actions: {
		addRewardsFromCart({ rewards }) {
			this.setRewards(rewards)
		},
		async toggleDiscount({ discountCode, action }) {
			let response
			const isAdd = action === 'add'

			const session = useSessionStore()
			try {
				if (isAdd) {
					response = await axios.post(session.checkoutShopRoute('discounts.store'), { discountCode })
				} else {
					response = await axios.delete(session.checkoutShopRoute('discounts.remove'))
				}
			} catch (error) {
				throw error
			}

			const { data } = response
			const cart = useCartStore()
			cart.setCartItems(data.cart.items)
			cart.setSubtotal(data.cart.subtotal)
			cart.setOrderDiscountAmount(data.cart.rewards.orderDiscountAmount)
			this.rewards = data.rewards

			return response
		},
		setRewards(rewards) {
			this.rewards = rewards
			// compute remaining logic changes
			let foundCoupon = null
			for (const reward of rewards) {
				if (reward.reward_id === 'palette_credit' || reward.reward_id === 'double_palette_credit') {
					// Palette Credit
					this.paletteRewards = reward
				} else if (reward.reward_id === 'hostess_reward_credit') {
					// Hostess Credit
					const { total_store_credit, remaining_store_credit, levels } = reward
					this.rewardCredit = total_store_credit
					this.rewardCreditRemaining = remaining_store_credit
					const total = levels.length
					let used = 0
					for (const level of levels) {
						if (level.claimed) used++
					}
					this.rewardDiscountsTotal = total
					this.rewardDiscountsUsed = used
				} else if (reward.reward_id === 'hostess_reward_fifty') {
					// Hostess 50% Off
					const { levels } = reward
					const total = levels.length
					let used = 0
					for (const level of levels) {
						if (level.claimed) used++
					}
					this.fiftyPercentItemsTotal = total
					this.fiftyPercentItemsUsed = used
				} else if (reward.reward_id === 'coupon') {
					foundCoupon = reward
				}
			}
			this.coupon = foundCoupon
		},
		async addLoyaltyCoupon(itemCode) {
			try {
				const { data } = await axios.post(route('checkout.loyalty.claim', { itemCode }))
				const { success } = data
				if (success) {
					toast({ type: 'success', title: 'Success', message: 'Coupon applied!' })
					window.location.reload()
				}
			} catch (error) {
				console.error('addLoyaltyCoupon() error: ', error)
				toast({
					type: 'error',
					title: 'Error',
					message: 'An error occured adding coupon. Please try again.',
					timeout: 5000,
				})
			}
		},
		async removeLoyaltyCoupon(itemCode) {
			try {
				const { data } = await axios.delete(route('checkout.loyalty.remove', { itemCode }))
				const { success } = data
				if (success) {
					toast({ type: 'success', title: 'Success', message: 'Coupon removed!' })
					window.location.reload()
				}
			} catch (error) {
				console.error('removeLoyaltyCoupon() error: ', error)
				toast({
					type: 'error',
					title: 'Error',
					message: 'An error occured removing coupon. Please try again.',
					timeout: 5000,
				})
			}
		},
	},
})

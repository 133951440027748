import { defineStore } from 'pinia'

export const useSessionStore = defineStore('session', {
	state: () => ({
		logged_in: false,
		currency_code: '',
		country_code: null,
		warehouse_id: null,
		billing_warehouse_id: null,
		language_code: null,
		language_id: null,
		price_type_id: null,
		shopping_context: null,
		party: {
			partyId: null,
			hostId: null,
			distributorId: null,
			description: null,
		},
		is_party_context: false,
		is_host_context: false,
		is_enroll_context: false,
		is_retail_context: false,
		is_wholesale_customer: false,
		party_contexts: [],
		checkout_url: '',
		web_alias: null,
		web_alias_artist_name: null,
		web_alias_artist_id: null,
		tracking_web_alias: null,
		tracking_artist_name: null,
		tracking_web_url: null,
		clear_tracking_web_url: null,
		item_search_index: null,
		modal_ids: [],
	}),

	getters: {
		isLoggedIn: (state) => state.logged_in,
		currencyCode: (state) => state.currency_code.toLowerCase(),
		countryCode: (state) => state.country_code,
		warehouseId: (state) => state.warehouse_id,
		billingWarehouseId: (state) => state.billing_warehouse_id,
		languageCode: (state) => state.language_code,
		languageId: (state) => state.language_id,
		priceTypeId: (state) => state.price_type_id,
		shoppingContext: (state) => state.shopping_context,
		partyId: (state) => state.party && state.party.partyId,
		partyDistributorId: (state) => state.party && state.party.distributorId,
		partyDescription: (state) => state.party && state.party.description,
		isPartyContext: (state) => state.is_party_context,
		isHostContext: (state) => state.is_host_context,
		isEnrollContext: (state) => state.is_enroll_context,
		isRetailContext: (state) => state.is_retail_context,
		isWholesaleCustomer: (state) => state.is_wholesale_customer,
		checkoutUrl: (state) => state.checkout_url,
		trackingWebAlias: (state) => state.tracking_web_alias,
		trackingArtistName: (state) => state.tracking_artist_name,
		webAlias: (state) => state.web_alias,
		webAliasArtistName: (state) => state.web_alias_artist_name,
		onMasterAccount: (state) => state.web_alias === 'www',
		webAliasArtistId: (state) => state.web_alias_artist_id,
		clearTrackingWebUrl: (state) => state.clear_tracking_web_url,
		trackingWebUrl: (state) => state.tracking_web_url,
		promptTrackingAlias: (state) => {
			return (
				(state.is_party_context || state.is_host_context) &&
				state.logged_in &&
				state.tracking_web_alias &&
				state.web_alias &&
				state.tracking_web_alias !== state.web_alias
			)
		},
		itemSearchIndex: (state) => state.item_search_index,
		hasSeenModal: (state) => (check) => state.modal_ids.some((id) => id === check),
		getShopParams:
			(state) =>
			(userDefinedParams = {}) => {
				let params = { ...userDefinedParams }
				let ctx = state.shopping_context

				if (params.hasOwnProperty('context')) {
					ctx = params.context
				}

				const { context, ...withoutContext } = params
				params = withoutContext

				if (!params.hasOwnProperty('language')) {
					params.language = state.language_code
				}

				if (!params.hasOwnProperty('activeParty') && state.is_party_context) {
					params.activeParty = state.party.partyId
				}

				return { context: ctx, params }
			},
		getCheckoutShopParams(state) {
			return (userDefinedParams = {}) => {
				let { context, params } = this.getShopParams(userDefinedParams)
				if (!params.hasOwnProperty('customerSite')) {
					params.customerSite = state.web_alias
				}
				return { context, params }
			}
		},
		shopRoute(state) {
			return (name, userDefinedParams = {}) => {
				const { context, params } = this.getShopParams(userDefinedParams)
				return route(`shop.${context}.${name}`, params)
			}
		},
		checkoutShopRoute(state) {
			return (name, userDefinedParams = {}) => {
				const { context, params } = this.getCheckoutShopParams(userDefinedParams)
                console.log(route(`shop.${context}.${name}`, params))
				return route(`shop.${context}.${name}`, params)
			}
		},
	},

	actions: {
		setLoggedIn(loggedIn) {
			this.logged_in = !!loggedIn
		},
		setCurrencyCode(code) {
			this.currency_code = code
		},
		setCountryCode(code) {
			this.country_code = code
		},
		setWarehouseId(id) {
			this.warehouse_id = id
		},
		setBillingWarehouseId(id) {
			this.billing_warehouse_id = id
		},
		setLanguageCode(code) {
			this.language_code = code
		},
		setLanguageId(id) {
			this.language_id = id
		},
		setPriceTypeId(id) {
			this.price_type_id = id
		},
		setShoppingContext(name) {
			this.shopping_context = name
		},
		setParty({ partyId = null, hostId = null, distributorId = null, description = null }) {
			this.party = { partyId, hostId, distributorId, description }
		},
		setPartyContexts(contexts) {
			this.party_contexts = contexts
		},
		setIsPartyContext(isPartyContext) {
			this.is_party_context = !!isPartyContext
		},
		setIsHostContext(isHostContext) {
			this.is_host_context = !!isHostContext
		},
		setIsEnrollContext(isEnrollContext) {
			this.is_enroll_context = !!isEnrollContext
		},
		setIsRetailContext(isRetailContext) {
			this.is_retail_context = !!isRetailContext
		},
		setIsWholesaleCustomer(isWholesaleCustomer) {
			this.is_wholesale_customer = !!isWholesaleCustomer
		},
		setCheckoutUrl(url) {
			this.checkout_url = url
		},
		setReward50PercentExclusion(exclusions) {
			this.reward_exclude_50_percent = exclusions
		},
		setWebAlias(webAlias) {
			this.web_alias = webAlias
		},
		setWebAliasArtistName(artistName) {
			this.web_alias_artist_name = artistName
		},
		setWebAliasArtistId(artistName) {
			this.web_alias_artist_id = artistName
		},
		setTrackingWebAlias(trackingWebAlias) {
			this.tracking_web_alias = trackingWebAlias
		},
		setTrackingArtistName(artistName) {
			this.tracking_artist_name = artistName
		},
		setClearTrackingWebUrl(clearTrackingWebUrl) {
			this.clear_tracking_web_url = clearTrackingWebUrl
		},
		setTrackingWebUrl(trackingWebUrl) {
			this.tracking_web_url = trackingWebUrl
		},
		setItemSearchKey(itemSearchIndex) {
			this.item_search_index = itemSearchIndex
		},
		setModalsFired(ids) {
			this.modal_ids = ids
		},
		addToModalsFired(id) {
			if (this.modal_ids.some((elm) => elm === id)) {
				return
			}
			const storagedIds = JSON.parse(localStorage.getItem('modal_ids'))
			const currentModalIds = storagedIds ?? []
			currentModalIds.push(id)
			localStorage.setItem('modal_ids', JSON.stringify(currentModalIds))
			this.modal_ids = currentModalIds
		},
	},
})

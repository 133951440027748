<template>
	<div class="site-wrapper h-full min-h-screen flex flex-col justify-between">
		<slot name="logout-form" />
		<header ref="siteHeader" class="site-header">
			<div :class="['site-header-inner', searchActive && 'search']">
				<div class="header--left">
					<div v-if="!isLargeMediaQuery" @click="handleMobileMenuToggle" class="menu-button--wrap">
						<button type="button" tabindex="0" aria-label="Menu" class="toggle-menu-button">
							<span class="menu-button--inner"><span class="menu-button--icon" /></span>
						</button>
					</div>
					<meili-product-search v-if="!isLargeMediaQuery" />
					<template v-if="isLargeMediaQuery">
						<slot name="site-logo" />
					</template>
				</div>
				<div class="header--center">
					<template v-if="!isLargeMediaQuery || isEnrollContext">
						<slot name="home-link" />
					</template>
					<template v-if="isEnrollContext">
						<slot name="brand-logo" />
					</template>
					<template v-if="isLargeMediaQuery">
						<nav ref="siteMenu" :data-state="shopMenuItemActive ? 'shop-menu-active' : null" class="mobile-menu">
							<div class="has-children-links is-grid-links">
								<button
									type="button"
									@click="handleShopMenuItemToggle"
									class="shop-link category-title"
									aria-label="Shop"
								>
									<span>Shop</span>
									<i><svg-vue icon="chevron-down" class="stroke-black" /></i>
								</button>
								<slot name="menu-links" />
							</div>
							<slot name="menu-other-links" />
						</nav>
					</template>
				</div>
				<div class="header--right">
					<button
						v-if="!searchActive && !isEnrollContext"
						type="button"
						@click="handleProfileToggle"
						class="link-to-profile"
					>
						<svg-vue icon="account" />
					</button>
					<meili-product-search v-if="isLargeMediaQuery" />
					<template v-if="!hideCart">
						<div v-if="!searchActive && !isEnrollContext" @click="handleBagToggle" class="link-to-bag">
							<button class="bag-with-count" aria-label="Shopping Bag">
								<svg-vue icon="bag" />
								<span :class="['count', quantityTotal > 0 ? '' : 'hide']">{{
									quantityTotal > 0 ? quantityTotal : null
								}}</span>
							</button>
						</div>
					</template>
				</div>
			</div>
		</header>
		<template v-if="!isLargeMediaQuery">
			<div class="off-screen-menu">
				<nav ref="siteMenu" :data-state="shopMenuItemActive ? 'shop-menu-active' : null" class="mobile-menu">
					<div class="has-children-links is-grid-links">
						<button type="button" @click="handleShopMenuItemToggle" class="shop-link category-title" aria-label="Shop">
							<span>Shop</span>
							<i><svg-vue icon="chevron-down" /></i>
						</button>
						<slot name="menu-links" />
					</div>
					<slot name="menu-other-links" />
				</nav>
			</div>
			<slot name="off-screen-menu" />
		</template>
		<reward-modal v-if="rewardModalOpen" />
		<div class="off-screen-profile-panel">
			<div @click="handleProfileToggle" class="profile-overlay" />
			<div class="profile-flyout">
				<slot name="profile-contents" />
			</div>
		</div>
		<template v-if="!hideCart">
			<div class="off-screen-bag-panel">
				<div @click="handleBagToggle" class="bag-overlay" />
				<slot name="cart-list" />
			</div>
		</template>
		<slot name="main" />
		<slot name="footer" />
		<div class="load--cover-whole-page">
			<span class="loader fixed-loader dark-loader" />
		</div>
		<slot name="shared-cart-modal" />
	</div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import '@sweetalert2/theme-minimal/minimal.css'
import 'vue-search-select/dist/VueSearchSelect.css'

import { useAppStore, useCartStore, useSessionStore, useRewardsStore } from '@stores'
import { defaultSwalOptions } from '@components/utils/helpers'

export default {
	props: {
		hideCart: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showEnrollmentMore: false,
		}
	},
	mounted() {
		window.addEventListener('resize', this.resizeHandler, { passive: true })

		this.$nextTick(() => {
			// Determine if the screen size is "desktop" or not
			if (window.matchMedia('(min-width: 1024px)').matches) {
				this.setLargeMediaQuery()
				// Unset the expanded 'Shop' menu item dropdown if not currently on a product category page or new releases page
				this.unsetShopMenuItemActive()

				this.getAndSetHeights(false)
			} else {
				this.unsetShopMenuItemActive()

				this.getAndSetHeights()
			}
		})
	},
	destroyed() {
		window.removeEventListener('resize', this.resizeHandler)
	},
	created() {
		this.commitRewards()
		this.commitCart()
		this.commitSession()

		if (this.promptTrackingAlias) {
			const newArtist = this.webAliasArtistName || this.webAlias
			const prevArtist = this.trackingArtistName || this.trackingWebAlias
			const newSiteIsParty = this.isPartyContext && this.partyDistributorId === this.webAliasArtistId
			const prevSiteWasParty = this.isPartyContext && this.partyDistributorId !== this.webAliasArtistId
			const newArtistIsNoArtist = this.webAlias === 'www'

			let swalHTML = `<P>You're about to leave ${prevArtist}'s site to shop with ${newArtist}.</p>`
			let confirmBtnTxt = `Shop with ${prevArtist}`
			let cancelBtnTxt = `Shop with ${newArtist}`

			if (newArtistIsNoArtist) {
				swalHTML = `<p>You're about to leave ${prevArtist}'s ${
					prevSiteWasParty ? 'Party' : 'site'
				} to shop without an Artist.</p>`
				confirmBtnTxt = prevSiteWasParty ? 'Back to Party' : `Shop with ${prevArtist}`
				cancelBtnTxt = 'Continue without an Artist'
			}
			if (prevSiteWasParty) {
				swalHTML = `<p>You're about to leave ${prevArtist}'s Party to shop with ${newArtist}.</p>`
				confirmBtnTxt = `Shop with ${prevArtist}`
				cancelBtnTxt = `Shop with ${newArtist}`
			}
			if (newSiteIsParty) {
				swalHTML = `<p>You're about to leave ${prevArtist}'s site to shop in ${newArtist}'s Party.</p>`
				confirmBtnTxt = `Shop with ${prevArtist}`
				cancelBtnTxt = 'Continue to Party'
			}

			Swal.fire({
				...defaultSwalOptions,
				showCloseButton: false,
				allowOutsideClick: false,
				allowEscapeKey: false,
				title: "Leaving Artist's Site",
				html: swalHTML,
				confirmButtonText: confirmBtnTxt,
				showCancelButton: true,
				cancelButtonText: cancelBtnTxt,
			}).then((result) => {
				const confirmHref = this.trackingWebUrl
				const cancelHref = this.clearTrackingWebUrl

				if (result.value) {
					location.href = confirmHref
				} else {
					location.href = cancelHref
				}
			})
		}
	},
	computed: {
		...mapState(useAppStore, ['searchActive', 'isLargeMediaQuery', 'shopMenuItemActive']),
		...mapState(useCartStore, ['rewardModalOpen', 'quantityTotal']),
		...mapState(useSessionStore, [
			'trackingWebUrl',
			'clearTrackingWebUrl',
			'promptTrackingAlias',
			'webAliasArtistName',
			'webAlias',
			'trackingArtistName',
			'trackingWebAlias',
			'webAliasArtistId',
			'shoppingContext',
			'isEnrollContext',
			'isPartyContext',
			'partyDistributorId',
		]),
	},
	methods: {
		...mapActions(useAppStore, [
			'setLargeMediaQuery',
			'unsetLargeMediaQuery',
			'unsetShopMenuItemActive',
			'toggleMobileMenu',
			'closeMobileMenu',
			'toggleShopMenuItem',
			'toggleAccountHeaderItem',
			'toggleProfilePanelActive',
			'toggleBagPanelActive',
			'toggleSearchActive',
		]),
		...mapActions(useRewardsStore, ['setRewards']),
		...mapActions(useCartStore, ['setCartItems', 'setSubtotal', 'setOrderDiscountAmount']),
		...mapActions(useSessionStore, [
			'setLoggedIn',
			'setCurrencyCode',
			'setCountryCode',
			'setWarehouseId',
			'setBillingWarehouseId',
			'setLanguageCode',
			'setLanguageId',
			'setPriceTypeId',
			'setShoppingContext',
			'setParty',
			'setPartyContexts',
			'setIsPartyContext',
			'setIsHostContext',
			'setIsEnrollContext',
			'setIsRetailContext',
			'setCheckoutUrl',
			'setIsWholesaleCustomer',
			'setWebAlias',
			'setWebAliasArtistName',
			'setWebAliasArtistId',
			'setTrackingWebAlias',
			'setTrackingArtistName',
			'setTrackingWebUrl',
			'setClearTrackingWebUrl',
			'setItemSearchKey',
			'setModalsFired',
		]),
		getAndSetHeights(apply = true) {
			const siteHeaderEl = this.$refs?.siteHeader
			const siteMenuEl = this.$refs?.siteMenu
			if (!siteHeaderEl || !siteMenuEl) return
			const windowHeight = window.innerHeight
			const siteHeaderHeight = siteHeaderEl.getBoundingClientRect().height

			if (siteMenuEl) {
				if (apply) {
					siteMenuEl.style.height = `${windowHeight - siteHeaderHeight}px`
				} else {
					siteMenuEl.style.height = ''
				}
			}
		},
		resizeHandler() {
			// Update the isLargeMediaQuery app state on screen resizing
			if (window.matchMedia('(min-width: 1024px)').matches) {
				if (!this.isLargeMediaQuery && this.shopMenuItemActive) {
					this.unsetShopMenuItemActive()
				}
				this.setLargeMediaQuery()
				this.getAndSetHeights(false)
			} else {
				this.unsetLargeMediaQuery()
				this.getAndSetHeights()
			}
		},
		commitRewards() {
			const ui_data = window.ui_data()

			if (!ui_data.store.modules.rewards) {
				return
			}
			const rewards = ui_data.store.modules.rewards
			this.setRewards(rewards)
		},
		commitCart() {
			const ui_data = window.ui_data()
			if (!ui_data.store.modules.cart) {
				return
			}
			const cart = ui_data.store.modules.cart

			const items = cart.items || []
			const subtotal = cart.subtotal || 0
			const orderDiscountAmount = cart.rewards.orderDiscountAmount || 0

			this.setCartItems(items)
			this.setSubtotal(subtotal)
			this.setOrderDiscountAmount(orderDiscountAmount)
		},
		commitSession() {
			const ui_data = window.ui_data()

			if (!ui_data.store.modules.session) {
				return
			}

			const session = ui_data.store.modules.session
			this.setLoggedIn(session.logged_in || false)
			this.setCurrencyCode(session.currency_code || null)
			this.setCountryCode(session.country_code || null)
			this.setWarehouseId(session.warehouse_id || null)
			this.setBillingWarehouseId(session.billing_warehouse_id || null)
			this.setLanguageCode(session.language_code || null)
			this.setLanguageId(session.language_id || null)
			this.setPriceTypeId(session.price_type_id || null)
			this.setShoppingContext(session.shopping_context || null)
			this.setParty(session.party || null)
			this.setPartyContexts(session.party_contexts || [])
			this.setIsPartyContext(!!session.is_party_context || false)
			this.setIsHostContext(!!session.is_host_context || false)
			this.setIsEnrollContext(!!session.is_enroll_context || false)
			this.setIsRetailContext(!!session.is_retail_context || false)
			this.setCheckoutUrl(session.checkout_url || [])
			this.setIsWholesaleCustomer(!!session.is_wholesale_customer || false)
			this.setWebAlias(session.web_alias || null)
			this.setWebAliasArtistName(session.web_alias_artist_name || null)
			this.setWebAliasArtistId(session.web_alias_artist_id || null)
			this.setTrackingWebAlias(session.tracking_web_alias || null)
			this.setTrackingArtistName(session.tracking_artist_name || null)
			this.setTrackingWebUrl(session.tracking_web_url || null)
			this.setClearTrackingWebUrl(session.clear_tracking_web_url || null)
			this.setItemSearchKey(session.item_search_index || null)
			const storagedIds = JSON.parse(localStorage.getItem('modal_ids'))
			this.setModalsFired(storagedIds ?? [])
		},
		handleMobileMenuToggle() {
			this.toggleMobileMenu()
		},
		handleMobileMenuClose() {
			this.closeMobileMenu()
		},
		handleEnrollmentNoticeToggle() {
			this.showEnrollmentMore = !this.showEnrollmentMore
		},
		handleShopMenuItemToggle() {
			this.toggleShopMenuItem()
		},
		handleAccountHeaderItemToggle() {
			this.toggleAccountHeaderItem()
		},
		handleProfileToggle() {
			this.toggleProfilePanelActive()
		},
		handleBagToggle() {
			this.toggleBagPanelActive()
		},
		handleSearchToggle() {
			this.closeMobileMenu()
			this.toggleSearchActive()
		},
	},
}
</script>
